#controlled-tab-example-tab-materials.active {
  color: black;
  border-radius: 0;
  background-color: white;
  border-bottom: 2px solid red;
}

#controlled-tab-example-tab-review.active {
  color: black;
  border-radius: 0;
  background-color: white;
  border-bottom: 2px solid red;
}
nav .nav-pills a{
    padding: 10px 10px;
}

.container.product {
  min-height: 100vh;
}

/* .react-stars-wrapper-008356492707285401{} */
/* .react-stars: {} */