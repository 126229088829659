@media (min-width: 768px) {
  .auth-container {
    position: relative;
  }

  .auth-container h1 {
    font-weight: bold;
    margin: 0;
  }

  .auth-container .container {
    margin: 100px auto;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
  }

  .auth-container .form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
  }

  .auth-container .form {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center;
  }

  .auth-container input {
    background-color: rgb(226, 221, 221);
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 85%;
    text-align: center;
    font-size: 13px;
    border-radius: 15px;
  }

  .auth-container .form-button {
    border-radius: 20px;
    border: 1px solid #388e3c;
    background-color: #388e3c;
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
    margin-top: 30px;
  }

  .auth-container .form-button:active {
    transform: scale(0.95);
  }
  .auth-container .form-button:focus {
    outline: none;
  }

  .auth-container .forget-password {
    color: gray;
    font-size: 12px;
    text-decoration: none;
    margin: 15px 0;
    letter-spacing: -0.5px;
    cursor: pointer;
  }

  .auth-container .forget-password:hover {
    color: #ff4b2b;
  }

  .auth-container .overlay-button {
    margin-top: 10px;
  }

  .auth-container .form-button.ghost {
    background-color: transparent;
    border-color: #ffffff;
  }

  .auth-container .sign-in-container {
    left: 0;
    width: 60%;
    z-index: 2;
    animation: show 0.6s;
  }

  .auth-container .sign-up-container {
    left: 0;
    width: 60%;
    opacity: 0;
    z-index: 1;
  }

  .auth-container .reset-container {
    left: 0;
    width: 60%;
    opacity: 0;
    z-index: 1;
  }

  .auth-container .sign-in-container h1,
  .auth-container .reset-container h1,
  .auth-container .sign-up-container h1 {
    margin-bottom: 30px;
  }

  .auth-container .container.right-panel-active .sign-in-container,
  .auth-container .container.forget-panel-active .sign-in-container {
    animation: hide 0.6s;
    transform: translateX(80%);
  }
  .auth-container .container.right-panel-active .sign-up-container {
    transform: translateX(67%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
  }

  .auth-container .container.forget-panel-active .reset-container {
    transform: translateX(67%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
  }

  @keyframes show {
    0%,
    49.99% {
      opacity: 0;
      z-index: 1;
    }

    50%,
    100% {
      opacity: 1;
      z-index: 5;
    }
  }

  @keyframes hide {
    0%,
    49.99% {
      opacity: 1;
    }

    50%,
    100% {
      opacity: 0;
      z-index: 0;
    }
  }

  .auth-container .overlay-container {
    position: absolute;
    top: 0;
    left: 60%;
    width: 40%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
  }

  .auth-container .container.right-panel-active .overlay-container,
  .auth-container .container.forget-panel-active .overlay-container {
    transform: translateX(-150%);
  }

  .auth-container .overlay {
    background: #388e3c;
/*     background: -webkit-linear-gradient(to right, #add100, #7b920a); */
/*     background: linear-gradient(to right, #add100, #7b920a); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #ffffff;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
  }

  .auth-container .container.right-panel-active .overlay,
  .auth-container .container.forget-panel-active .overlay {
    transform: translateX(50%);
  }

  .auth-container .overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
  }

  .auth-container .overlay-left {
    transform: translateX(-20%);
  }

  .auth-container .container.right-panel-active .overlay-left,
  .auth-container .container.forget-panel-active .overlay-left {
    transform: translateX(0);
  }

  .auth-container .overlay-right {
    padding: 0;
    right: 0;
    transform: translateX(0);
  }

  .auth-container .container.right-panel-active .overlay-right {
    transform: translateX(20%);
  }
}

@media (max-width: 768px) {
  .auth-container .form-container {
    background: whitesmoke;
  }

  .auth-container .forget-password {
    color: gray;
    font-size: 15px;
    text-decoration: none;
    margin-top: 10px;
    letter-spacing: -0.5px;
    cursor: pointer;
  }
  .auth-container .forget-password:hover {
    color: #ff4b2b;
  }
  .auth-container .form-button {
    border-radius: 20px;
    border: 1px solid #388e3c;
    background-color: #388e3c;
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    padding: 10px 40px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
    margin-top: 10px;
  }

  .auth-container .form-button.ghost {
    background-color: transparent;
    border-color: #ffffff;
  }

  .auth-container .form-button:active {
    transform: scale(0.95);
  }

  .auth-container .form-button:focus {
    outline: none;
  }
  .auth-container .container {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    width: 554px;
    max-width: 100%;
    min-height: 600px;
    height: 100%;
    margin: 100px auto;
  }

  .auth-container .form-container .form {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 60%;
    text-align: center;
  }
  .auth-container .form-container input {
    background-color: rgb(226, 221, 221);
    border: none;
    padding: 12px 15px;
    margin: 5px 0;
    width: 85%;
    text-align: center;
    font-size: 13px;
    border-radius: 15px;
  }
  .auth-container .form-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 0.6s ease-in-out;
  }
  .auth-container .sign-in-container {
    height: 100%;
    z-index: 2;
    transform: translateY(0);
  }

  .auth-container .sign-up-container,
  .auth-container .reset-container {
    top: 40%;
    transform: translateY(100%);
    z-index: 0;
    height: 100%;
  }

  .auth-container .sign-in-container h1,
  .auth-container .reset-container h1,
  .auth-container .sign-up-container h1 {
    margin-bottom: 20px;
  }

  .auth-container .container.right-panel-active .sign-in-container {
    transform: translateX(-100%);
  }

  .auth-container .container.right-panel-active .sign-up-container {
    transform: translateY(0);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
  }

  @keyframes show {
    0%,
    49.99% {
      opacity: 0;
      z-index: 1;
    }

    50%,
    100% {
      opacity: 1;
      z-index: 5;
    }
  }

  .auth-container .container.forget-panel-active .reset-container {
    transform: translateY(0);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
  }

  .auth-container .container.forget-panel-active .sign-in-container {
    transform: translateX(-100%);
  }

  .auth-container .overlay-container {
    position: absolute;
    /* background: #8a6159; */
    top: 60%;
    height: 40%;
    left: 0;
    width: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
  }

  .auth-container .container.right-panel-active .overlay-container,
  .auth-container .container.forget-panel-active .overlay-container {
    transform: translateY(-150%);
  }

  .auth-container .container.right-panel-active .overlay,
  .auth-container .container.forget-panel-active .overlay {
    transform: translateY(50%);
  }
  .auth-container .overlay-panel {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    bottom: 0;
    height: 50%;
    width: 100%;
    transform: translateY(0);
    transition: transform 0.6s ease-in-out;
  }

  .auth-container .overlay-left {
    transform: translateY(-20%);
  }

  .auth-container .container.right-panel-active .overlay-left,
  .auth-container .container.forget-panel-active .overlay-left {
    transform: translateY(0);
  }
  .auth-container .overlay-right {
    /* bottom: 0; */
    transform: translateY(0);
  }
  .auth-container .container.right-panel-active .overlay-right {
    transform: translateY(20%);
  }

  .auth-container .overlay {
    background: #388e3c;
/*     background: -webkit-linear-gradient(to bottom, #add100, #7b920a); */
/*     background: linear-gradient(to bottom, #add100, #7b920a); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #ffffff;
    position: relative;
    top: -100%;
    height: 200%;
    width: 100%;
    transform: translateY(0);
    transition: transform 0.6s ease-in-out;
  }
}

@media (max-width: 580px) {
  .auth-container .sign-in-container h1,
  .auth-container .reset-container h1,
  .auth-container .sign-up-container h1,
  .auth-container .overlay-container h1 {
    font-size: calc(100% + 1vw + 1vh);
    font-weight: bold;
  }
}
@media (max-width: 580px) {
  .auth-container {
    width: 95%;
  }
}

.auth-container input:focus {
  outline: none;
}
