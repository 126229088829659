.reset-password-container .container {
  margin: 100px auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 568px;
  max-width: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.reset-password-container .form-container {
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.reset-password-container .form-container .form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}
.reset-password-container input {
  background-color: rgb(226, 221, 221);
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 85%;
  text-align: center;
  font-size: 13px;
  border-radius: 15px;
}

.form-button {
  border-radius: 20px;
  border: 1px solid #add100;
  background-color: #add100;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  margin-top: 30px;
}

.form-button:active {
  transform: scale(0.95);
}
.form-button:focus {
  outline: none;
}

.reset-password-container h1.form-title {
  margin-bottom: 30px;
}
/* .reset-password-container */
