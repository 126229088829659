.title {
  font-weight: 500;
  text-align: left;
}
.p.para {
  text-align: left;
  font-weight: normal;
}
.containers {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
  text-align: left;
}
