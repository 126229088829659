.contact {
  /* background:rgb(129, 199, 132); */
  /* border-color: rgb(129, 199, 132); */
  /*#98c659*/
  position: relative;
  width: 100%;
  opacity: 0.9;
  overflow: hidden;
  /* padding: 30px 0; */
}

/* Bact to top button */

.back-button-footer .btn-success {
  position: absolute;
  top: 0;
  left: 0;
  border-style: none;
  background-color:#4caf50!important;
  /*#bde686;*/
  color: #000000;
  border-color: "none";
}

.back-button-footer .btn-success:focus {
  outline: "none";
}


.mission-points{
  padding: 20px 20%;
  text-align: justify;
  font-size: 16px;
}
.mission-point{
  padding: 5px;
}

/* -----------------form.js css ---------------------- */

.form-section .form-group {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

/* form-label */
label {
  font-weight: 500;
  letter-spacing: 1.5px;
}

/*form-button*/
form .btn-success {
  background-color: #325600;
}

form input {
  font-size: 13px;
}

form .btn-success:focus {
  outline: "none";
}
/* ----------------- address.js css ------------------------ */

a:link {
  color: #000000;
}

.copyright h6 {
  color: #325600;
  letter-spacing: 0.8px;
  font-weight: 500;
}

@media (max-width: 992px) {
  .container {
    width: 100%;
    max-width: none;
  }
}

@media (max-width: 768px) {
  .form-section .form-group {
    flex-direction: row;
    justify-content: start;
  }
  .contact-col{
    margin: 15px 0;
  }
}
.contact-heading h2 {
  margin-top: 40px;
  color:#000000e3 !important;
  text-align:center;
  font-weight:600;
  font-size: 35px;
  line-height: 1.0;
}
.contact-heading p{
  font-weight:500;
  margin-top:25px;
  text-align:justify;
  color:black;
  padding-left:35px; 
  padding-right:35px;
}


