.slider {
  position: relative;
  width: 80%;

  margin: 70px 0;
  /* filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.6)); */
}
/* .slider .galrjd {
  background-color: #f7f7f7;
  width: 95vw;
  padding: 0px 20px;
  border-radius: 25px;
} */

.slider .item-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95px;
  width: 80%;
  color: #fff;
  border-radius: 25px;
  font-size: 4em;
  background-color:"#ffffff";
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  width: 80%;
  color: #fff;
  
  font-size: 4em;
  background-color:"#ffffff"
}

/* .slider .rec.rec-arrow:hover {
  background-color: "none";
} */

/* .slider .rec-carousel-item:focus {
  outline: none;
  background-color: "grey";
  box-shadow: inset 0 0 1px 1px lightgrey;
} */
