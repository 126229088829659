.tnc-container {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 3%;
  padding-bottom: 2%;
  text-align: left;
}

.tnc-container h2.title {
  text-align: left;
  margin-bottom: 30px;
}
/* .p.para {
  text-align: left;
  font-weight: normal;
} */
