@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&family=Poppins:wght@200;300;400;500;600;700&display=swap);
*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

body {
	font-family: "Poppins", "Montserrat", sans-serif !important;
	font-size: 16px;
	line-height: 1.7;
	box-sizing: border-box;
}

li,
a {
	text-decoration: none;
}
 
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2grey' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%grey' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

h1,h2,h3,h4,h5,h6,p,a{
	font-style:Montserrat;
}



header {
  position: -webkit-sticky;
  position: sticky;
  z-index: 999;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0 8px 6px -6px #999;
}

.top {
  /*padding-top: 25px;
  background: #f4f2f3;*/
  padding-left: 120px;
}
.bottom-nav {
  background-color: #4caf50 !important;
  /*a6dd5e*/
}

h4.logoname {
  margin-bottom: 0;
  font-size: 19px;
  /*font-weight: 700;*/
  /*letter-spacing: 2.5px;*/
}

.collapse .navbar-nav a:hover {
  color: white;
}

.collapse .navbar-nav .nav-link {
  color: black;
  font-weight: 500;
}

/* The underline for active link*/
.hover-border a.nav-link {
  position: relative;
}

.hover-border a.nav-link:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #555555;
  visibility: hidden;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.hover-border a.nav-link:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.nav-item {
  padding: 0 8px;
}

.bottom-nav .navbar-nav a.nav-link:hover {
  color: white;
}

.bottom-nav .navbar-nav a.nav-link {
  color: black;
  font-weight: 500;
}

/* For the H2h logo*/

.home-logo {
  position: absolute;
  top: 2px;
  left: 25px;
  height: 48px;
  z-index: 1;
}

/* .home-text {
  position: absolute;
  top: 34%;
  left: 10%;
} */

h4.home-text {
  font-size: 17px;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .home-text {
    display: none;
  }
}

/* #logo-home-pseudo::before {
 content: "";
  width: 96px;
  height: 96px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 15px;
  background: #f4f2f3;
  z-index: 1;
}*/

/* For the H2h lion-logo*/
@media screen and (max-width: 1240px) {
  .lion-logo {
    display: none;
  }
}
.lion-logo {
  position: absolute;
  top: 0;
  right: 5%;
  margin-top: 10px;
  margin-right: 15px;
}

#lionshadow {
  border-radius: 50%;
  width: 80px;
  height: 10px;
  background: black;
  opacity: 0.5;
  -webkit-filter: blur(10px);
          filter: blur(10px);
  -webkit-transform: scale(1, 0.2);
          transform: scale(1, 0.2);
}

span.cart-basket[datacount]::after {
  position: absolute;
  right: -10px;
  top: -10px;
  content: attr(datacount);
  font-size: 80%;
  padding: 0 5px;
  border-radius: 50%;
  color: black;
  text-align: top;
  font-weight: bold;
  background: white;
}

span.cart-basket[datacount="0"]::after {
  position: relative;
  content: "";
  background: none;
}

@media screen and (max-width: 1040px) {
  .card-word {
    display: none;
  }
}

.bg-black {
  background-color: #1e1e1e;
}

.footer .bg-black-light {
  background-color: "#1e1e1e";
}
.footer .divider {
  background-color: "#1e1e1e";
  height: 1px;
  width: 100%;
}

.footer .nav-link{
  font-size: medium;
}

.footer{
   box-shadow:  0 5px 5px 10px #999;
   /* box-shadow:inset 0 15px 5px -16px #999; */
}

.section {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.section::before {
    content: "";
    background-image: url(/static/media/background.acc3c4d3.jpg);
    background-size: contain;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.9;
}
.contact {
  /* background:rgb(129, 199, 132); */
  /* border-color: rgb(129, 199, 132); */
  /*#98c659*/
  position: relative;
  width: 100%;
  opacity: 0.9;
  overflow: hidden;
  /* padding: 30px 0; */
}

/* Bact to top button */

.back-button-footer .btn-success {
  position: absolute;
  top: 0;
  left: 0;
  border-style: none;
  background-color:#4caf50!important;
  /*#bde686;*/
  color: #000000;
  border-color: "none";
}

.back-button-footer .btn-success:focus {
  outline: "none";
}


.mission-points{
  padding: 20px 20%;
  text-align: justify;
  font-size: 16px;
}
.mission-point{
  padding: 5px;
}

/* -----------------form.js css ---------------------- */

.form-section .form-group {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

/* form-label */
label {
  font-weight: 500;
  letter-spacing: 1.5px;
}

/*form-button*/
form .btn-success {
  background-color: #325600;
}

form input {
  font-size: 13px;
}

form .btn-success:focus {
  outline: "none";
}
/* ----------------- address.js css ------------------------ */

a:link {
  color: #000000;
}

.copyright h6 {
  color: #325600;
  letter-spacing: 0.8px;
  font-weight: 500;
}

@media (max-width: 992px) {
  .container {
    width: 100%;
    max-width: none;
  }
}

@media (max-width: 768px) {
  .form-section .form-group {
    flex-direction: row;
    justify-content: start;
  }
  .contact-col{
    margin: 15px 0;
  }
}
.contact-heading h2 {
  margin-top: 40px;
  color:#000000e3 !important;
  text-align:center;
  font-weight:600;
  font-size: 35px;
  line-height: 1.0;
}
.contact-heading p{
  font-weight:500;
  margin-top:25px;
  text-align:justify;
  color:black;
  padding-left:35px; 
  padding-right:35px;
}



.carousal {
    position: relative;
    /*padding: 30px 0;*/
}

.carousel-indicators li {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: grey;
}

.carousel-indicators .active {
    background-color: #232323;
}

.carousel-indicators {
    bottom: -25px;
}
 
.carousel-inner {
    margin-bottom: 50px;
}

/*About.js*/

.about {
  position: relative;
  padding: 40px 0;
}

.heading {
  text-align: center;
  margin-bottom: 30px;
  font-size: 40px;
  line-height: 1.1;
  font-weight: 600;
}

.about li {
  font-size: 16px;
  padding: 15px 0 15px 0;
}

@media screen and (max-width: 768px) {
  .heading {
    margin-bottom: 24px;
    font-size: 36px;
    line-height: 1;
    font-weight: 600;
  }
}

.headingsocial {
    margin-bottom: 30px;
    font-size: 40px;
    line-height: 1.0;
    font-weight: 600;
    color:#000000d5 !important;
    text-align: center !important;
}

.Img {
    max-width: 94%;
    border-radius: 1%;
    -webkit-filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, .2));
            filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, .2));
}

.imgsocial {
    border: 0;
    max-width: 100%;
    display: inline-block;
}

.pillar-points{
    padding-left: 10px;
}
.pillar-point{
    font-weight: bold;
}
.div-wide-text{
    padding-top: 15px;
}
.wide-text{
    font-size: 16px;
    text-align: center;
    font-weight: 500;
}

.slider {
  position: relative;
  width: 80%;

  margin: 70px 0;
  /* filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.6)); */
}
/* .slider .galrjd {
  background-color: #f7f7f7;
  width: 95vw;
  padding: 0px 20px;
  border-radius: 25px;
} */

.slider .item-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95px;
  width: 80%;
  color: #fff;
  border-radius: 25px;
  font-size: 4em;
  background-color:"#ffffff";
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  width: 80%;
  color: #fff;
  
  font-size: 4em;
  background-color:"#ffffff"
}

/* .slider .rec.rec-arrow:hover {
  background-color: "none";
} */

/* .slider .rec-carousel-item:focus {
  outline: none;
  background-color: "grey";
  box-shadow: inset 0 0 1px 1px lightgrey;
} */

.tnc-container {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 3%;
  padding-bottom: 2%;
  text-align: left;
}

.tnc-container h2.title {
  text-align: left;
  margin-bottom: 30px;
}
/* .p.para {
  text-align: left;
  font-weight: normal;
} */

@media (min-width: 768px) {
  .auth-container {
    position: relative;
  }

  .auth-container h1 {
    font-weight: bold;
    margin: 0;
  }

  .auth-container .container {
    margin: 100px auto;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
  }

  .auth-container .form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
  }

  .auth-container .form {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center;
  }

  .auth-container input {
    background-color: rgb(226, 221, 221);
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 85%;
    text-align: center;
    font-size: 13px;
    border-radius: 15px;
  }

  .auth-container .form-button {
    border-radius: 20px;
    border: 1px solid #388e3c;
    background-color: #388e3c;
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: -webkit-transform 80ms ease-in;
    transition: transform 80ms ease-in;
    transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
    margin-top: 30px;
  }

  .auth-container .form-button:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  .auth-container .form-button:focus {
    outline: none;
  }

  .auth-container .forget-password {
    color: gray;
    font-size: 12px;
    text-decoration: none;
    margin: 15px 0;
    letter-spacing: -0.5px;
    cursor: pointer;
  }

  .auth-container .forget-password:hover {
    color: #ff4b2b;
  }

  .auth-container .overlay-button {
    margin-top: 10px;
  }

  .auth-container .form-button.ghost {
    background-color: transparent;
    border-color: #ffffff;
  }

  .auth-container .sign-in-container {
    left: 0;
    width: 60%;
    z-index: 2;
    -webkit-animation: show 0.6s;
            animation: show 0.6s;
  }

  .auth-container .sign-up-container {
    left: 0;
    width: 60%;
    opacity: 0;
    z-index: 1;
  }

  .auth-container .reset-container {
    left: 0;
    width: 60%;
    opacity: 0;
    z-index: 1;
  }

  .auth-container .sign-in-container h1,
  .auth-container .reset-container h1,
  .auth-container .sign-up-container h1 {
    margin-bottom: 30px;
  }

  .auth-container .container.right-panel-active .sign-in-container,
  .auth-container .container.forget-panel-active .sign-in-container {
    -webkit-animation: hide 0.6s;
            animation: hide 0.6s;
    -webkit-transform: translateX(80%);
            transform: translateX(80%);
  }
  .auth-container .container.right-panel-active .sign-up-container {
    -webkit-transform: translateX(67%);
            transform: translateX(67%);
    opacity: 1;
    z-index: 5;
    -webkit-animation: show 0.6s;
            animation: show 0.6s;
  }

  .auth-container .container.forget-panel-active .reset-container {
    -webkit-transform: translateX(67%);
            transform: translateX(67%);
    opacity: 1;
    z-index: 5;
    -webkit-animation: show 0.6s;
            animation: show 0.6s;
  }

  @-webkit-keyframes show {
    0%,
    49.99% {
      opacity: 0;
      z-index: 1;
    }

    50%,
    100% {
      opacity: 1;
      z-index: 5;
    }
  }

  @keyframes show {
    0%,
    49.99% {
      opacity: 0;
      z-index: 1;
    }

    50%,
    100% {
      opacity: 1;
      z-index: 5;
    }
  }

  @-webkit-keyframes hide {
    0%,
    49.99% {
      opacity: 1;
    }

    50%,
    100% {
      opacity: 0;
      z-index: 0;
    }
  }

  @keyframes hide {
    0%,
    49.99% {
      opacity: 1;
    }

    50%,
    100% {
      opacity: 0;
      z-index: 0;
    }
  }

  .auth-container .overlay-container {
    position: absolute;
    top: 0;
    left: 60%;
    width: 40%;
    height: 100%;
    overflow: hidden;
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
    z-index: 100;
  }

  .auth-container .container.right-panel-active .overlay-container,
  .auth-container .container.forget-panel-active .overlay-container {
    -webkit-transform: translateX(-150%);
            transform: translateX(-150%);
  }

  .auth-container .overlay {
    background: #388e3c;
/*     background: -webkit-linear-gradient(to right, #add100, #7b920a); */
/*     background: linear-gradient(to right, #add100, #7b920a); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #ffffff;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
  }

  .auth-container .container.right-panel-active .overlay,
  .auth-container .container.forget-panel-active .overlay {
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }

  .auth-container .overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
  }

  .auth-container .overlay-left {
    -webkit-transform: translateX(-20%);
            transform: translateX(-20%);
  }

  .auth-container .container.right-panel-active .overlay-left,
  .auth-container .container.forget-panel-active .overlay-left {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  .auth-container .overlay-right {
    padding: 0;
    right: 0;
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }

  .auth-container .container.right-panel-active .overlay-right {
    -webkit-transform: translateX(20%);
            transform: translateX(20%);
  }
}

@media (max-width: 768px) {
  .auth-container .form-container {
    background: whitesmoke;
  }

  .auth-container .forget-password {
    color: gray;
    font-size: 15px;
    text-decoration: none;
    margin-top: 10px;
    letter-spacing: -0.5px;
    cursor: pointer;
  }
  .auth-container .forget-password:hover {
    color: #ff4b2b;
  }
  .auth-container .form-button {
    border-radius: 20px;
    border: 1px solid #388e3c;
    background-color: #388e3c;
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    padding: 10px 40px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: -webkit-transform 80ms ease-in;
    transition: transform 80ms ease-in;
    transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
    margin-top: 10px;
  }

  .auth-container .form-button.ghost {
    background-color: transparent;
    border-color: #ffffff;
  }

  .auth-container .form-button:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }

  .auth-container .form-button:focus {
    outline: none;
  }
  .auth-container .container {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    overflow: hidden;
    width: 554px;
    max-width: 100%;
    min-height: 600px;
    height: 100%;
    margin: 100px auto;
  }

  .auth-container .form-container .form {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 60%;
    text-align: center;
  }
  .auth-container .form-container input {
    background-color: rgb(226, 221, 221);
    border: none;
    padding: 12px 15px;
    margin: 5px 0;
    width: 85%;
    text-align: center;
    font-size: 13px;
    border-radius: 15px;
  }
  .auth-container .form-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 0.6s ease-in-out;
  }
  .auth-container .sign-in-container {
    height: 100%;
    z-index: 2;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  .auth-container .sign-up-container,
  .auth-container .reset-container {
    top: 40%;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    z-index: 0;
    height: 100%;
  }

  .auth-container .sign-in-container h1,
  .auth-container .reset-container h1,
  .auth-container .sign-up-container h1 {
    margin-bottom: 20px;
  }

  .auth-container .container.right-panel-active .sign-in-container {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }

  .auth-container .container.right-panel-active .sign-up-container {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
    z-index: 5;
    -webkit-animation: show 0.6s;
            animation: show 0.6s;
  }

  @-webkit-keyframes show {
    0%,
    49.99% {
      opacity: 0;
      z-index: 1;
    }

    50%,
    100% {
      opacity: 1;
      z-index: 5;
    }
  }

  @keyframes show {
    0%,
    49.99% {
      opacity: 0;
      z-index: 1;
    }

    50%,
    100% {
      opacity: 1;
      z-index: 5;
    }
  }

  .auth-container .container.forget-panel-active .reset-container {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
    z-index: 5;
    -webkit-animation: show 0.6s;
            animation: show 0.6s;
  }

  .auth-container .container.forget-panel-active .sign-in-container {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }

  .auth-container .overlay-container {
    position: absolute;
    /* background: #8a6159; */
    top: 60%;
    height: 40%;
    left: 0;
    width: 100%;
    overflow: hidden;
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
    z-index: 100;
  }

  .auth-container .container.right-panel-active .overlay-container,
  .auth-container .container.forget-panel-active .overlay-container {
    -webkit-transform: translateY(-150%);
            transform: translateY(-150%);
  }

  .auth-container .container.right-panel-active .overlay,
  .auth-container .container.forget-panel-active .overlay {
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  .auth-container .overlay-panel {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    bottom: 0;
    height: 50%;
    width: 100%;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
  }

  .auth-container .overlay-left {
    -webkit-transform: translateY(-20%);
            transform: translateY(-20%);
  }

  .auth-container .container.right-panel-active .overlay-left,
  .auth-container .container.forget-panel-active .overlay-left {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  .auth-container .overlay-right {
    /* bottom: 0; */
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  .auth-container .container.right-panel-active .overlay-right {
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
  }

  .auth-container .overlay {
    background: #388e3c;
/*     background: -webkit-linear-gradient(to bottom, #add100, #7b920a); */
/*     background: linear-gradient(to bottom, #add100, #7b920a); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #ffffff;
    position: relative;
    top: -100%;
    height: 200%;
    width: 100%;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    transition: -webkit-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
  }
}

@media (max-width: 580px) {
  .auth-container .sign-in-container h1,
  .auth-container .reset-container h1,
  .auth-container .sign-up-container h1,
  .auth-container .overlay-container h1 {
    font-size: calc(100% + 1vw + 1vh);
    font-weight: bold;
  }
}
@media (max-width: 580px) {
  .auth-container {
    width: 95%;
  }
}

.auth-container input:focus {
  outline: none;
}

.container.address {
  min-height: 100vh;
}

.reset-password-container .container {
  margin: 100px auto;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  width: 568px;
  max-width: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.reset-password-container .form-container {
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.reset-password-container .form-container .form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}
.reset-password-container input {
  background-color: rgb(226, 221, 221);
  border: none;
  padding: 12px 15px;
  margin: 8px 0;
  width: 85%;
  text-align: center;
  font-size: 13px;
  border-radius: 15px;
}

.form-button {
  border-radius: 20px;
  border: 1px solid #add100;
  background-color: #add100;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
  margin-top: 30px;
}

.form-button:active {
  -webkit-transform: scale(0.95);
          transform: scale(0.95);
}
.form-button:focus {
  outline: none;
}

.reset-password-container h1.form-title {
  margin-bottom: 30px;
}
/* .reset-password-container */

.title {
  font-weight: 500;
  text-align: left;
}
.p.para {
  text-align: left;
  font-weight: normal;
}
.containers {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5%;
  text-align: left;
}

.revieworder {
  margin-left: 10%;
  margin-right: 10%;
}


.container.shop {
  min-height: 100vh;
}

#ads {
  margin: 30px 0;
}

/* #ads .card-detail-badge {
  background: #28a745;
  text-align: center;
  border-radius: 30px 30px 30px 30px;
  color: #000;
  padding: 5px 10px;
  font-size: 12px;
} */

#ads .card:hover {
  background: #fff;
  box-shadow: 12px 15px 20px 0px rgba(46, 61, 73, 0.15);
  border-radius: 4px;
  transition: all 0.3s ease;
}

#ads .card-image-overlay span {
  display: inline-block;
}



.container.shop {
  min-height: 100vh;
}

#ads {
  margin: 30px 0;
}

/* #ads .card-detail-badge {
  background: #28a745;
  text-align: center;
  border-radius: 30px 30px 30px 30px;
  color: #000;
  padding: 5px 10px;
  font-size: 12px;
} */

#ads .card:hover {
  background: #fff;
  box-shadow: 12px 15px 20px 0px rgba(46, 61, 73, 0.15);
  border-radius: 4px;
  transition: all 0.3s ease;
}

#ads .card-image-overlay span {
  display: inline-block;
}


#controlled-tab-example-tab-materials.active {
  color: black;
  border-radius: 0;
  background-color: white;
  border-bottom: 2px solid red;
}

#controlled-tab-example-tab-review.active {
  color: black;
  border-radius: 0;
  background-color: white;
  border-bottom: 2px solid red;
}
nav .nav-pills a{
    padding: 10px 10px;
}

.container.product {
  min-height: 100vh;
}

/* .react-stars-wrapper-008356492707285401{} */
/* .react-stars: {} */
.cart {
  margin-left: 8%;
  margin-right: 8%;
}

@-webkit-keyframes swing {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    10% {
      -webkit-transform: rotate(10deg);
              transform: rotate(10deg);
    }
    30% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    40% {
      -webkit-transform: rotate(-10deg);
              transform: rotate(-10deg);
    }
    50% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    60% {
      -webkit-transform: rotate(5deg);
              transform: rotate(5deg);
    }
    70% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    80% {
      -webkit-transform: rotate(-5deg);
              transform: rotate(-5deg);
    }
    100% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
  }
  
  @keyframes swing {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    10% {
      -webkit-transform: rotate(10deg);
              transform: rotate(10deg);
    }
    30% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    40% {
      -webkit-transform: rotate(-10deg);
              transform: rotate(-10deg);
    }
    50% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    60% {
      -webkit-transform: rotate(5deg);
              transform: rotate(5deg);
    }
    70% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    80% {
      -webkit-transform: rotate(-5deg);
              transform: rotate(-5deg);
    }
    100% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
  }
  
  @-webkit-keyframes sonar {
    0% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(2);
              transform: scale(2);
      opacity: 0;
    }
  }
  
  @keyframes sonar {
    0% {
      -webkit-transform: scale(0.9);
              transform: scale(0.9);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(2);
              transform: scale(2);
      opacity: 0;
    }
  }
  body {
    font-size: 0.9rem;
  }
  .page-wrapper .sidebar-wrapper,
  .sidebar-wrapper .sidebar-brand > a,
  .sidebar-wrapper .sidebar-dropdown > a:after,
  .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before,
  .sidebar-wrapper ul li a i,
  .page-wrapper .page-content,
  .sidebar-wrapper .sidebar-search input.search-menu,
  .sidebar-wrapper .sidebar-search .input-group-text,
  .sidebar-wrapper .sidebar-menu ul li a,
  #show-sidebar,
  #close-sidebar {
    transition: all 0.3s ease;
  }
  
  /*----------------page-wrapper----------------*/
  
  .page-wrapper {
    height: 100vh;
  }
  
  .page-wrapper .theme {
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 4px;
    margin: 2px;
  }
  
  /* .page-wrapper .theme.chiller-theme { */
    /* background: #1e2229; */
  /* } */
  
  /*----------------toggeled sidebar----------------*/
  
  .page-wrapper.toggled .sidebar-wrapper {
    left: 0px;
  }
  
  @media screen and (min-width: 768px) {
    .page-wrapper.toggled .page-content {
      padding-left: 300px;
    }
  }
  /*----------------show sidebar button----------------*/
  #show-sidebar {
    position: fixed;
    left: 0;
    top: 10px;
    border-radius: 0 4px 4px 0px;
    width: 35px;
    transition-delay: 0.3s;
  }
  .page-wrapper.toggled #show-sidebar {
    left: -40px;
  }
  /*----------------sidebar-wrapper----------------*/
  
  .sidebar-wrapper {
    width: 260px;
    height: 100%;
    max-height: 100%;
    position: fixed;
    top: 0;
    left: -300px;
    z-index: 999;
  }
  
  .sidebar-wrapper ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .sidebar-wrapper a {
    text-decoration: none;
  }
  
  /*----------------sidebar-content----------------*/
  
  .sidebar-content {
    max-height: calc(100% - 30px);
    height: calc(100% - 30px);
    overflow-y: auto;
    position: relative;
  }
  
  .sidebar-content.desktop {
    overflow-y: hidden;
  }
  
  /*--------------------sidebar-brand----------------------*/
  
  .sidebar-wrapper .sidebar-brand {
    padding: 10px 20px;
    display: flex;
    align-items: center;
  }
  
  .sidebar-wrapper .sidebar-brand > a {
    text-transform: uppercase;
    flex-grow: 1;
  }
  
  .sidebar-wrapper .sidebar-brand #close-sidebar {
    cursor: pointer;
    font-size: 20px;
  }
  /*--------------------sidebar-header----------------------*/
  
  .sidebar-wrapper .sidebar-header {
    padding: 20px;
    overflow: hidden;
  }
  
  .sidebar-wrapper .sidebar-header .user-pic {
    float: left;
    width: 60px;
    padding: 2px;
    border-radius: 12px;
    margin-right: 15px;
    overflow: hidden;
  }
  
  .sidebar-wrapper .sidebar-header .user-pic img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  
  .sidebar-wrapper .sidebar-header .user-info {
    float: left;
  }
  
  .sidebar-wrapper .sidebar-header .user-info > span {
    display: block;
  }
  
  .sidebar-wrapper .sidebar-header .user-info .user-role {
    font-size: 12px;
  }
  
  .sidebar-wrapper .sidebar-header .user-info .user-status {
    font-size: 11px;
    margin-top: 4px;
  }
  
  .sidebar-wrapper .sidebar-header .user-info .user-status i {
    font-size: 8px;
    margin-right: 4px;
    color: #5cb85c;
  }
  
  /*-----------------------sidebar-search------------------------*/
  
  .sidebar-wrapper .sidebar-search > div {
    padding: 10px 20px;
  }
  
  /*----------------------sidebar-menu-------------------------*/
  
  .sidebar-wrapper .sidebar-menu {
    padding-bottom: 10px;
  }
  
  .sidebar-wrapper .sidebar-menu .header-menu span {
    font-size: 14px;
    padding: 15px 20px 5px 20px;
    display: inline-block;
  }
  
  .sidebar-wrapper .sidebar-menu ul li a {
    display: inline-block;
    width: 100%;
    text-decoration: none;
    position: relative;
    padding: 8px 30px 8px 20px;
  }
  
  .sidebar-wrapper .sidebar-menu ul li a i {
    margin-right: 10px;
    font-size: 12px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 4px;
  }
  
  .sidebar-wrapper .sidebar-menu ul li a:hover > i::before {
    display: inline-block;
    -webkit-animation: swing ease-in-out 0.5s 1 alternate;
            animation: swing ease-in-out 0.5s 1 alternate;
  }
  
  .sidebar-wrapper .sidebar-menu .sidebar-dropdown > a:after {
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    display: inline-block;
    font-style: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background: 0 0;
    position: absolute;
    right: 15px;
    top: 14px;
  }
  
  .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
    padding: 5px 0;
  }
  
  .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li {
    padding-left: 25px;
    font-size: 13px;
  }
  
  .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a:before {
    content: "\f111";
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 10px;
    font-size: 8px;
  }
  
  .sidebar-wrapper .sidebar-menu ul li a span.label,
  .sidebar-wrapper .sidebar-menu ul li a span.badge {
    float: right;
    margin-top: 8px;
    margin-left: 5px;
  }
  
  .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .badge,
  .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu li a .label {
    float: right;
    margin-top: 0px;
  }
  
  .sidebar-wrapper .sidebar-menu .sidebar-submenu {
    display: none;
  }
  
  .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    right: 17px;
  }
  
  /*--------------------------side-footer------------------------------*/
  
  .sidebar-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
  }
  
  .sidebar-footer > a {
    flex-grow: 1;
    text-align: center;
    height: 30px;
    line-height: 30px;
    position: relative;
  }
  
  .sidebar-footer > a .notification {
    position: absolute;
    top: 0;
  }
  
  .badge-sonar {
    display: inline-block;
    background: #980303;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    position: absolute;
    top: 0;
  }
  
  .badge-sonar:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid #980303;
    opacity: 0;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    -webkit-animation: sonar 1.5s infinite;
            animation: sonar 1.5s infinite;
  }
  
  /*--------------------------page-content-----------------------------*/
  
  .page-wrapper .page-content {
    display: inline-block;
    width: 100%;
    padding-left: 0px;
    padding-top: 20px;
  }
  
  .page-wrapper .page-content > div {
    padding: 20px 40px;
  }
  
  .page-wrapper .page-content {
    overflow-x: hidden;
  }
  
  /*------scroll bar---------------------*/
  
  ::-webkit-scrollbar {
    width: 5px;
    height: 7px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #525965;
    border: 0px none #ffffff;
    border-radius: 0px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #525965;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #525965;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  ::-webkit-scrollbar-track:hover {
    background: transparent;
  }
  ::-webkit-scrollbar-track:active {
    background: transparent;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
  
  
  /*-----------------------------chiller-theme-------------------------------------------------*/
  
  .chiller-theme .sidebar-wrapper {
    background-image: url(/static/media/side_backg.2b2433e6.jpeg) !important;
    color:#000000;
  }
  
  .chiller-theme .sidebar-wrapper .sidebar-header,
  .chiller-theme .sidebar-wrapper .sidebar-search,
  .chiller-theme .sidebar-wrapper .sidebar-menu {
      border-top: 1px solid #3a3f48;
  }
  
  .chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
  .chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
      border-color: transparent;
      box-shadow: none;
  }
  
  .chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-role,
  .chiller-theme .sidebar-wrapper .sidebar-header .user-info .user-status,
  .chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
  .chiller-theme .sidebar-wrapper .sidebar-search .input-group-text,
  .chiller-theme .sidebar-wrapper .sidebar-brand>a,
  .chiller-theme .sidebar-wrapper .sidebar-menu ul li a,
  .chiller-theme .sidebar-footer>a {
      color: #000000;
  }
  
  .chiller-theme .sidebar-wrapper .sidebar-menu ul li:hover>a,
  .chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active>a,
  .chiller-theme .sidebar-wrapper .sidebar-header .user-info,
  .chiller-theme .sidebar-wrapper .sidebar-brand>a:hover,
  .chiller-theme .sidebar-footer>a:hover i {
      color: #000000;
  }
  
  .page-wrapper.chiller-theme.toggled #close-sidebar {
      color: #000000;
  }
  
  .page-wrapper.chiller-theme.toggled #close-sidebar:hover {
      color: #ffffff;
  }
  
  .chiller-theme .sidebar-wrapper ul li:hover a i,
  .chiller-theme .sidebar-wrapper .sidebar-dropdown .sidebar-submenu li a:hover:before,
  .chiller-theme .sidebar-wrapper .sidebar-search input.search-menu:focus+span,
  .chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active a i {
      color: #16c7ff;
      text-shadow:0px 0px 10px rgba(22, 199, 255, 0.5);
  }
  
  .chiller-theme .sidebar-wrapper .sidebar-menu ul li a i,
  .chiller-theme .sidebar-wrapper .sidebar-menu .sidebar-dropdown div,
  .chiller-theme .sidebar-wrapper .sidebar-search input.search-menu,
  .chiller-theme .sidebar-wrapper .sidebar-search .input-group-text {
      background: #3a3f48;
  }
  
  .chiller-theme .sidebar-wrapper .sidebar-menu .header-menu span {
      color: #6c7b88;
  }
  
  .chiller-theme .sidebar-footer {
      background: #3a3f48;
      box-shadow: 0px -1px 5px #282c33;
      border-top: 1px solid #464a52;
  }
  
  .chiller-theme .sidebar-footer>a:first-child {
      border-left: none;
  }
  
  .chiller-theme .sidebar-footer>a:last-child {
      border-right: none;
  }
  
