.carousal {
    position: relative;
    /*padding: 30px 0;*/
}

.carousel-indicators li {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: grey;
}

.carousel-indicators .active {
    background-color: #232323;
}

.carousel-indicators {
    bottom: -25px;
}
 
.carousel-inner {
    margin-bottom: 50px;
}
