.headingsocial {
    margin-bottom: 30px;
    font-size: 40px;
    line-height: 1.0;
    font-weight: 600;
    color:#000000d5 !important;
    text-align: center !important;
}

.Img {
    max-width: 94%;
    border-radius: 1%;
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, .2));
}

.imgsocial {
    border: 0;
    max-width: 100%;
    display: inline-block;
}

.pillar-points{
    padding-left: 10px;
}
.pillar-point{
    font-weight: bold;
}
.div-wide-text{
    padding-top: 15px;
}
.wide-text{
    font-size: 16px;
    text-align: center;
    font-weight: 500;
}
