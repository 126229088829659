/*About.js*/

.about {
  position: relative;
  padding: 40px 0;
}

.heading {
  text-align: center;
  margin-bottom: 30px;
  font-size: 40px;
  line-height: 1.1;
  font-weight: 600;
}

.about li {
  font-size: 16px;
  padding: 15px 0 15px 0;
}

@media screen and (max-width: 768px) {
  .heading {
    margin-bottom: 24px;
    font-size: 36px;
    line-height: 1;
    font-weight: 600;
  }
}
