header {
  position: sticky;
  z-index: 999;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;

  -webkit-box-shadow: 0 8px 6px -6px #999;
  -moz-box-shadow: 0 8px 6px -6px #999;
  box-shadow: 0 8px 6px -6px #999;
}

.top {
  /*padding-top: 25px;
  background: #f4f2f3;*/
  padding-left: 120px;
}
.bottom-nav {
  background-color: #4caf50 !important;
  /*a6dd5e*/
}

h4.logoname {
  margin-bottom: 0;
  font-size: 19px;
  /*font-weight: 700;*/
  /*letter-spacing: 2.5px;*/
}

.collapse .navbar-nav a:hover {
  color: white;
}

.collapse .navbar-nav .nav-link {
  color: black;
  font-weight: 500;
}

/* The underline for active link*/
.hover-border a.nav-link {
  position: relative;
}

.hover-border a.nav-link:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #555555;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.hover-border a.nav-link:hover:before {
  visibility: visible;
  transform: scaleX(1);
}

.nav-item {
  padding: 0 8px;
}

.bottom-nav .navbar-nav a.nav-link:hover {
  color: white;
}

.bottom-nav .navbar-nav a.nav-link {
  color: black;
  font-weight: 500;
}

/* For the H2h logo*/

.home-logo {
  position: absolute;
  top: 2px;
  left: 25px;
  height: 48px;
  z-index: 1;
}

/* .home-text {
  position: absolute;
  top: 34%;
  left: 10%;
} */

h4.home-text {
  font-size: 17px;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .home-text {
    display: none;
  }
}

/* #logo-home-pseudo::before {
 content: "";
  width: 96px;
  height: 96px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 15px;
  background: #f4f2f3;
  z-index: 1;
}*/

/* For the H2h lion-logo*/
@media screen and (max-width: 1240px) {
  .lion-logo {
    display: none;
  }
}
.lion-logo {
  position: absolute;
  top: 0;
  right: 5%;
  margin-top: 10px;
  margin-right: 15px;
}

#lionshadow {
  border-radius: 50%;
  width: 80px;
  height: 10px;
  background: black;
  opacity: 0.5;
  filter: blur(10px);
  transform: scale(1, 0.2);
}

span.cart-basket[datacount]::after {
  position: absolute;
  right: -10px;
  top: -10px;
  content: attr(datacount);
  font-size: 80%;
  padding: 0 5px;
  border-radius: 50%;
  color: black;
  text-align: top;
  font-weight: bold;
  background: white;
}

span.cart-basket[datacount="0"]::after {
  position: relative;
  content: "";
  background: none;
}

@media screen and (max-width: 1040px) {
  .card-word {
    display: none;
  }
}
