
.container.shop {
  min-height: 100vh;
}

#ads {
  margin: 30px 0;
}

/* #ads .card-detail-badge {
  background: #28a745;
  text-align: center;
  border-radius: 30px 30px 30px 30px;
  color: #000;
  padding: 5px 10px;
  font-size: 12px;
} */

#ads .card:hover {
  background: #fff;
  box-shadow: 12px 15px 20px 0px rgba(46, 61, 73, 0.15);
  border-radius: 4px;
  transition: all 0.3s ease;
}

#ads .card-image-overlay span {
  display: inline-block;
}

