.bg-black {
  background-color: #1e1e1e;
}

.footer .bg-black-light {
  background-color: "#1e1e1e";
}
.footer .divider {
  background-color: "#1e1e1e";
  height: 1px;
  width: 100%;
}

.footer .nav-link{
  font-size: medium;
}

.footer{
   box-shadow:  0 5px 5px 10px #999;
   /* box-shadow:inset 0 15px 5px -16px #999; */
}
